import { Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom, Observable, Observer } from "rxjs";
import { finalize, map, skip } from "rxjs/operators";
import { HttpResponse } from "@angular/common/http";
import { IInvitation, IInvitationCompanyInfo } from "app/shared/model/invitation.model";
import { InvitationService } from "app/shared/dataservices/invitation.service";
import { IProject } from "app/shared/model/project.model";
import { ApplicationStateService } from "app/core/application-state.service";
import { SelectInputData } from "app/shared/components/common/select-input/select-input.component";
import { IQuoter } from "app/shared/model/quoter.model";
import { ProjectApi } from "app/shared/dataservices/project.api";
import { BpAlertService } from "app/shared/services/bp-alert.service";
import { ActivatedRoute, Router } from "@angular/router";
import {
    ScheduleComparisonScreenNavigatorService
} from "app/shared/services/schedule-comparison-screen-navigator.service";
import {
    RequestProjectQuoterModalService
} from "app/flows/scheduler/components/request-project-quoter-modal/request-project-quoter-modal.service";
import { IsSchedulerProjectReadOnlyService } from "app/shared/services/is-scheduler-project-read-only.service";
import {
    UpdateInvitationVersionStatusCheckingService
} from "app/shared/services/update-invitation-version-status-checking.service";
import { AccountService } from "app/core/auth/account.service";
import {
    BuilderHasBeenInvitedToQuoteModalService
} from "app/flows/scheduler/components/builder-has-been-invited-to-quote-modal/builder-has-been-invited-to-quote-modal.service";
import { LocalStorageService } from "ngx-webstorage";
import Swal from "sweetalert2";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import * as _ from 'lodash';
import { RegionApi } from "app/shared/dataservices/region.api";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

@Injectable()
@UntilDestroy()
export class QuotesService {
    @BlockUI() blockUI: NgBlockUI;

    suggestedQuotersSubject: BehaviorSubject<IQuoter[]> = new BehaviorSubject<IQuoter[]>([]);
    suggestedQuoters$: Observable<IQuoter[]> = this.suggestedQuotersSubject.asObservable();

    invitationsSubject: BehaviorSubject<IInvitation[]> = new BehaviorSubject<IInvitation[]>([]);
    invitations$ = this.invitationsSubject.asObservable();
    newInvitation: IInvitationCompanyInfo = null;
    alreadyInvitedCompaniesInputData: SelectInputData;
    showCompareQuotesButton = false;
    showDefaultQuoterButton = false;
    isShowAverageRatesButtonEnabled = false;
    isUpdateAllToLatestVersionButtonEnabled = false;

    showReSubmit = false;
    selectCompanyMode = true;
    readOnly = true;
    possibleQuoters: IQuoter[] = [];
    proxyAdmin = false;
    private suggestedQuotersPage = 0;
    private readonly suggestedQuotersSize = 5;

    constructor(private _appStateService: ApplicationStateService,
                private _invitationService: InvitationService,
                private _projectApi: ProjectApi,
                private _regionApi: RegionApi,
                private _alertService: BpAlertService,
                private _activatedRoute: ActivatedRoute,
                private _router: Router,
                private _route: ActivatedRoute,
                private _scheduleComparisonScreenNavigatorService: ScheduleComparisonScreenNavigatorService,
                private _requestProjectQuoterModalService: RequestProjectQuoterModalService,
                private _isSchedulerProjectReadOnlyService: IsSchedulerProjectReadOnlyService,
                private _updateInvitationVersionStatusCheckingService: UpdateInvitationVersionStatusCheckingService,
                private _accountService: AccountService,
                private _builderHasBeenInvitedToQuoteModalService: BuilderHasBeenInvitedToQuoteModalService,
                private _localStorage: LocalStorageService) {
    }

    private _totalSuggestedQuotersCount = 0;

    public get totalSuggestedQuotersCount(): number {
        return this._totalSuggestedQuotersCount;
    }

    get invitations(): IInvitation[] {
        return this.invitationsSubject.value;
    }

    set invitations(value: IInvitation[]) {
        this.invitationsSubject.next(value);
    }

    get project(): IProject {
        return this._appStateService.project;
    }

    get showContractorFeature(): boolean {
        return this._accountService.isBeta();
    }

    private get invitationOfNotLatestVersion(): IInvitation[] {
        return this.invitations.filter(invitation => {
            return !(invitation.quoterId == null || invitation.majorVersion + '.' + invitation.minorVersion == this.project.version)
        });
    }

    init(): void {
        this.proxyAdmin = this._localStorage.retrieve('proxyAdmin');
        this.readOnly = this._isSchedulerProjectReadOnlyService.isReadOnly(this.project);

        this._invitationService.queryPossibleQuoterList(this.project.id)
            .subscribe((res) => {
                this.possibleQuoters = res.body;
            });

        this.loadInvitations().subscribe(() => {
            this.fillAlreadyInvitedCompaniesSearchBySelectInputData();
        });

        this._route.queryParams.subscribe(params => {
            this.showReSubmit = params['showReSubmit'] === 'true';
        });

        if (this.showContractorFeature) {
            this.invitationsSubject.asObservable()
                .pipe(untilDestroyed(this), skip(1))
                .subscribe(() => {
                    this._totalSuggestedQuotersCount = 0;
                    this.suggestedQuotersPage = 0;
                    this.updateSuggestedQuoters();
                })
        }
    }

    loadMoreSuggestedQuoters(): void {
        this.suggestedQuotersPage++;
        this.updateSuggestedQuoters();
    }

    loadInvitations(): Observable<void> {
        return new Observable((observer: Observer<void>) => {
            this.blockUI.start('Please wait..');

            this._invitationService
                .query(this.project.id)
                .pipe(
                    finalize(() => {
                        this.blockUI.stop();
                    })
                )
                .subscribe((res: HttpResponse<IInvitation[]>) => {
                    this.isShowAverageRatesButtonEnabled = _.find(res.body, (invitation: IInvitation) => invitation.status === 'NOT_INVITED') != null;

                    this.invitations = _.orderBy(
                        res.body.filter(invitation => invitation.status !== 'NOT_INVITED'), ['isDefault', 'id'], ['desc', 'asc']);

                    this.updateIsUpdateAllToLatestVersionButtonEnabled();

                    _.each(_.filter(this.invitations, { processing: true }), (invitation: IInvitation) => {
                        this._updateInvitationVersionStatusCheckingService.addUpdateVersionStatusChecking(this.project, invitation);
                    });

                    _.each(
                        _.filter(
                            this.invitations,
                            (invitation: IInvitation) => invitation.isDefault || this.project.defaultQuoter.id === invitation.quoterId
                        ),
                        (invitation: IInvitation) => {
                            this.updateInvitationTotal(invitation);
                        }
                    );

                    this.showCompareQuotesButton =
                        _.find(
                            this.invitations,
                            (invitation: IInvitation) =>
                                invitation.quoterId != null && (invitation.status === 'QUOTED' || invitation.status === 'ACCEPTED')
                        ) != null;

                    this.showDefaultQuoterButton = _.find(this.invitations, (invitation: IInvitation) => invitation.isDefault) == null;

                    if (this.invitations.length === 0) {
                        this.project.status = 'Draft';
                    }

                    observer.next();
                    observer.complete();
                });
        });
    }

    inviteBuilder(): void {
        this.newInvitation = {};
    }

    sendInvite(): void {
        this.blockUI.start('Please wait..');

        this._invitationService
            .sendInvitation(this.project.id, this.newInvitation.company, this.newInvitation.email, this.newInvitation.phone, this.newInvitation.autoInvite)
            .pipe(
                finalize(() => {
                    this.selectCompanyMode = true;
                    this.blockUI.stop();
                })
            )
            .subscribe(
                (res: HttpResponse<any>) => {
                    const update = () => {
                        this.newInvitation = null;
                        this.invitations = [...this.invitations, res.body];
                        this.fillAlreadyInvitedCompaniesSearchBySelectInputData();
                    }

                    if (!this._accountService.doNotShowAgainSendInviteReminder()) {
                        this._builderHasBeenInvitedToQuoteModalService.open().result.then(() => {
                            update();
                        });
                    } else {
                        this._alertService.success('Invitation successfully sent');
                        update();
                    }
                }
            );
    }

    compareQuotes(): void {
        this._scheduleComparisonScreenNavigatorService.navigate(this.project, this.invitations);
    }

    requestProfessional(): void {
        this._requestProjectQuoterModalService.open(this.project.id);
    }

    addDefaultQuoter(): void {
        this.blockUI.start('Please wait..');

        this._invitationService
            .addDefaultQuoters(this.project.id)
            .pipe(
                finalize(() => {
                    this.blockUI.stop();
                })
            )
            .subscribe(
                () => {
                    this._alertService.success('Invitation default quoter success');
                    this.newInvitation = null;
                    this.loadInvitations().subscribe();
                }
            );
    }

    acceptQuote(invitation: IInvitation): void {
        this.blockUI.start('Please wait..');

        this._invitationService
            .acceptQuote(invitation.id)
            .pipe(
                finalize(() => {
                    this.blockUI.stop();
                })
            )
            .subscribe(
                () => {
                    this.project.status = 'Quote Accepted';
                    this._alertService.success('Invitation was accepted');
                    this.loadInvitations().subscribe();
                }
            );
    }

    onDefaultQuoterChanged(invitation: IInvitation): void {
        this._projectApi.updateDefaultQuoter(this.project.id, invitation.quoterId).subscribe(() => {
            this.project.defaultQuoter = this.project.defaultQuoter || {};
            this.project.defaultQuoter.id = invitation.quoterId;
        });
    }

    goToIndividualQuote(invitation: IInvitation): void {
        if (invitation.isSelfQuoter) {
            this._router.navigate(['quoter', 'quote', this.project.id]);
        } else {
            this._router.navigate(['../../individual-quote', this.project.id, invitation.quoterId], { relativeTo: this._activatedRoute });
        }
    }

    canAcceptQuote(invitation: IInvitation): boolean {
        return invitation.quoterId != null && invitation.status === 'QUOTED' && !invitation.isDefault;
    }

    canViewIndividualQuote(invitation: IInvitation): boolean {
        return invitation.quoterId != null && (invitation.isDefault || _.indexOf(['QUOTED', 'VARIATIONS_MADE', 'ACCEPTED'], invitation.status) > -1);
    }

    canInviteBuilder(): boolean {
        return _.indexOf(['Variations Made', 'Quote Accepted'], this.project.status) === -1;
    }

    isInvitationDefault(invitation: IInvitation): boolean {
        if (!this.project.defaultQuoter) {
            return false;
        }

        return invitation.quoterId === this.project.defaultQuoter.id;
    }

    canReSubmitQuoter(): boolean {
        return this.showReSubmit;
    }

    onUpdateToLatestVersionClick(invitation: IInvitation): void {
        this.updateToLatestVersion(invitation).then(() => {
            this.updateIsUpdateAllToLatestVersionButtonEnabled();
        });
    }

    isInvitationVersionTheSameAsProjectVersion(invitation: IInvitation): boolean {
        return invitation.majorVersion + '.' + invitation.minorVersion === this.project.version;
    }

    onMaterialSelectionChange(event): void {
        if (event != null) {
            this.newInvitation = event;
        }
    }

    isDefaultQuoterCheckBoxDisabled(invitation: IInvitation): boolean {
        return (!invitation.isDefault && (invitation.status === 'SENT' || invitation.status === 'IN_PROGRESS'));
    }

    isMe(invitation: IInvitation): boolean {
        return this._accountService.getEmail() === invitation.email;
    }

    confirmReSubmitQuoter(invitation: any): void {
        Swal.fire({
            title: 'Are you sure?',
            text: 'This action cannot be undone.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Re-submit',
            customClass: {
                confirmButton: 'btn btn-danger btn-border-radius waves-effect m-r-10',
                cancelButton: 'btn btn-secondary btn-border-radius waves-effect'
            }
        }).then((result) => {
            if (result.isConfirmed) {
                this.blockUI.start('Please wait..');

                this._projectApi
                    .reSubmitQuoter(this.project.id, invitation.quoterId)
                    .pipe(
                        finalize(() => {
                            this.blockUI.stop();
                        })
                    )
                    .subscribe(() => {
                        this.loadInvitations().subscribe();
                    });
            }
        });
    }

    async updateAllToLatestVersion(): Promise<void> {
        const updatePromises = this.invitationOfNotLatestVersion.map(
            (invitation) => () => this.updateToLatestVersion(invitation)
        );

        this.blockUI.start('Please wait...');
        try {
            // Sequentially update each invitation
            for (const updatePromise of updatePromises) {
                await updatePromise();
            }

            // Add default quoters
            await lastValueFrom(this._invitationService.addDefaultQuoters(this.project.id));

            // Reload invitations and emit the change event
            this.loadInvitations().subscribe();
            this.updateIsUpdateAllToLatestVersionButtonEnabled();
        } catch (error) {
            console.error('An error occurred while updating:', error);
        } finally {
            this.blockUI.stop();
        }
    }

    showAverageRates(): void {
        this.blockUI.start('Please wait...');
        this._invitationService.addDefaultQuoters(this.project.id).pipe(finalize(() => {
            this.blockUI.stop();
        })).subscribe(() => {
            this.loadInvitations().subscribe();
        })
    }

    confirmRemoveInvitation(invitation: IInvitation): void {
        Swal.fire({
            title: 'Are you sure?',
            text: 'This action cannot be undone.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Delete',
            customClass: {
                confirmButton: 'btn btn-danger btn-border-radius waves-effect m-r-10',
                cancelButton: 'btn btn-secondary btn-border-radius waves-effect'
            }
        }).then((result) => {
            if (result.isConfirmed) {
                this.blockUI.start('Please wait..');

                this._invitationService
                    .delete(invitation.id)
                    .pipe(
                        finalize(() => {
                            this.blockUI.stop();
                        })
                    )
                    .subscribe(
                        () => {
                            this._alertService.success('Invitation successfully removed');
                            this.loadInvitations().subscribe();
                        }
                    );
            }
        });
    }

    private fillAlreadyInvitedCompaniesSearchBySelectInputData(): void {
        this.blockUI.start('Please wait..');

        this._invitationService
            .previous()
            .pipe(
                finalize(() => {
                    this.blockUI.stop();
                })
            )
            .subscribe((res: HttpResponse<IInvitationCompanyInfo[]>) => {
                const alreadyInvitedCompanies = _.filter(res.body, (ic: IInvitationCompanyInfo) => {
                    return _.findIndex(this.invitations, (inv: IInvitation) => inv.email.toLowerCase() === ic.email.toLowerCase()) === -1;
                });

                this.alreadyInvitedCompaniesInputData = {
                    indexProperty: 'company',
                    titleProperty: 'company',
                    data: alreadyInvitedCompanies,
                    dropdownPosition: 'bottom',
                    clearable: false,
                    onEnterClickHandler: this.onCompaniesSelectInputEnterClick.bind(this)
                };
            });
    }

    private updateInvitationTotal(invitation: IInvitation): void {
        this._projectApi.getTotalForProjectByQuoter(this.project.id, invitation.quoterId).subscribe((res: HttpResponse<number>) => {
            invitation.total = res.body;
        });
    }

    private onCompaniesSelectInputEnterClick(currentTextValue: string): void {
        if (currentTextValue.length > 0) {
            this.newInvitation = { company: currentTextValue };
            this.selectCompanyMode = false;
        }
    }

    private async updateToLatestVersion(invitation: IInvitation): Promise<void> {
        const res = await lastValueFrom(this._projectApi
            .updateQuoterToCurrentProjectVersion(this.project.id, invitation.quoterId));
        switch (res.body) {
            case 'UP_TO_DATE':
                invitation.majorVersion = Number(this.project.version.split('.')[0]);
                invitation.minorVersion = Number(this.project.version.split('.')[1]);
                break;
            case 'IN_PROGRESS':
                invitation.processing = true;
                this._updateInvitationVersionStatusCheckingService.addUpdateVersionStatusChecking(this.project, invitation);
                break;
        }
    }

    private updateIsUpdateAllToLatestVersionButtonEnabled(): void {
        this.isUpdateAllToLatestVersionButtonEnabled = this.invitationOfNotLatestVersion.length > 0;
    }

    private updateSuggestedQuoters(): void {
        const regionId = this.project.region.id;
        if (regionId == null) {
            return;
        }

        this._regionApi.quoters(regionId, {
            page: this.suggestedQuotersPage,
            size: this.suggestedQuotersSize
        }).pipe(
            map((quotersRes) => {
                this._totalSuggestedQuotersCount = Number(quotersRes.headers.get('x-total-count') || 0);
                const existingEmails = new Set(this.suggestedQuotersSubject.value.map(q => q.email));
                const newQuoters = quotersRes.body.filter(q => !existingEmails.has(q.email));
                this.suggestedQuotersSubject.next([...this.suggestedQuotersSubject.value, ...newQuoters]);
            })
        ).subscribe()
    }

}
