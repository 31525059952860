import { Injectable } from '@angular/core';
import { BpAlertService } from 'app/shared/services/bp-alert.service';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class BpOnErrorService {
    constructor(private bpAlertService: BpAlertService) {}

    showError(errResp: HttpErrorResponse) {
        const error = errResp.error || {};
        const errorMessage = error.detail ?? error.title ?? error.message ?? 'An unexpected error occurred, we are trying to fix it as soon as possible';
        this.bpAlertService.error(errorMessage, 10000);
    }
}
