import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IRegion } from 'app/shared/model/region.model';
import { SERVER_API_URL } from 'app/app.constants';
import { createRequestOption } from 'app/shared/util/request-util';
import { IInvitation, IInvitationCompanyInfo } from 'app/shared/model/invitation.model';
import { IQuoter } from 'app/shared/model/quoter.model';

@Injectable({ providedIn: 'root' })
export class InvitationService {
    public resourceUrl = SERVER_API_URL + 'api/invitations';
    public acceptQuoterUrl = SERVER_API_URL + 'api/invitations/accept';
    public projectUrl = SERVER_API_URL + 'api/projects';
    public registerQuoterUrl = SERVER_API_URL + 'api/register-quoter';
    public acceptTeamMemberUrl = SERVER_API_URL + 'api/project-team';

    constructor(private http: HttpClient) {}

    sendInvitation(projectId: number, company: string, email: string, phone: string, autoInvite?: boolean): Observable<any> {
        const params = autoInvite ? new HttpParams().set('autoInvite', true) : new HttpParams();
        return this.http.post<any>(this.resourceUrl, { projectId, company, email, phone }, { observe: 'response', params });
    }

    acceptQuote(id: number): Observable<any> {
        return this.http.post<any>(this.acceptQuoterUrl, null, {
            params: createRequestOption({ id }),
            observe: 'response'
        });
    }

    addDefaultQuoters(projectId: number): Observable<number[]> {
        return this.http.post<number[]>(`${this.resourceUrl}/add-default-quoter/${projectId}`, { observe: 'response' });
    }

    validateInvitationToken(token: string): Observable<any> {
        const options = createRequestOption({ token });
        return this.http.post<any>(`${this.resourceUrl}/user-exist`, null, { params: options, observe: 'response' });
    }

    declineInvitationToken(token: string): Observable<any> {
        const options = createRequestOption({ token });
        return this.http.post<any>(`${this.resourceUrl}/decline`, null, { params: options, observe: 'response' });
    }

    registerQuoter(token: string, password: string, region: IRegion): Observable<any> {
        return this.http.post<any>(this.registerQuoterUrl, { token, password, region }, { observe: 'response' });
    }

    acceptInvitation(token: string): Observable<any> {
        const options = createRequestOption({ token });
        return this.http.post<any>(`${this.resourceUrl}/apply`, null, { params: options, observe: 'response' });
    }

    query(projectId: number): Observable<HttpResponse<IInvitation[]>> {
        return this.http.get<IInvitation[]>(`${this.projectUrl}/${projectId}/invitations`, {
            observe: 'response'
        });
    }

    queryPossibleQuoterList(projectId: number): Observable<HttpResponse<IQuoter[]>> {
        return this.http.get<IQuoter[]>(`${this.resourceUrl}/default-quoters/${projectId}`, {
            observe: 'response'
        });
    }

    delete(id: number): Observable<HttpResponse<any>> {
        return this.http.delete<any>(`${this.resourceUrl}/${id}`, { observe: 'response' });
    }

    previous(): Observable<HttpResponse<IInvitationCompanyInfo[]>> {
        return this.http.get<IInvitationCompanyInfo[]>(`${this.resourceUrl}/previous`, {
            observe: 'response'
        });
    }

    acceptTeamMember(token: string): Observable<any> {
        return this.http.put<any>(`${this.acceptTeamMemberUrl}/${token}`, null, { observe: 'response' });
    }
}
