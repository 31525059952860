<div class="flex justify-content-start flex-wrap gap-10">
    <bp-main-view-filter
        class="d-none d-md-block"
        *ngIf="dashboardStore.inited && appState.data.scheduleAreaItems?.length && appState.data.stageItems?.length"
        [disabled]="dashboardStore?.inProcess"
        [disabledRoomMode]="true"
        (onChanged)="onMainViewFilterChangedFunc($event)"
        [initialFilterState]="dashboardStore.filterState"
        [areaItems]="appState.data.scheduleAreaItems"
        [stageItems]="appState.data.stageItems">
    </bp-main-view-filter>

    <div *ngIf="appState.project.archive"
         class="m-l-20"
         style="margin-top: 7px">
        <small class="label label-warning"
               title="Project archived">
            Archived
        </small>
    </div>

    <div class="flex-1 flex justify-content-end">
        <button (click)="onShowUpdateButtonClick()"
                *ngIf="dashboardStore.dashboardMode == null && !dashboardStore.readOnly && dashboardStore.isShowUpdateButtonEnabled"
                [disabled]="dashboardStore?.inProcess"
                ngbTooltip="Update"
                class="d-none d-md-inline btn btn-secondary btn-border-radius m-r-10">
            Update
        </button>

        <button (click)="onShowAverageRatesButtonClick()"
                *ngIf="dashboardStore.dashboardMode == null && !dashboardStore.readOnly && dashboardStore.isShowAverageRatesButtonEnabled"
                [disabled]="dashboardStore?.inProcess"
                ngbTooltip="Show Average Rates"
                class="d-none d-md-inline btn btn-secondary btn-border-radius m-r-10">
            Show Average Rates
        </button>

        <button (click)="onShowAverageRatesButtonClick()"
                *ngIf="dashboardStore.dashboardMode == null && !dashboardStore.readOnly && dashboardStore.isShowAverageRatesButtonEnabled"
                [disabled]="dashboardStore?.inProcess"
                ngbTooltip="Show Average Rates"
                class="d-md-none btn btn-secondary btn-circle m-r-10">
            <i class="material-icons md-24">
                star
            </i>
        </button>

        <button (click)="copyDashboardLinkToBuffer()"
                *ngIf="!appState.isHomeownerView() && dashboardStore.dashboardMode == null"
                [disabled]="dashboardStore?.inProcess"
                class="d-none d-md-inline btn btn-secondary btn-border-radius m-r-10"
                ngbTooltip="Dashboard Link">
            Dashboard Link
        </button>

        <button (click)="copyDashboardLinkToBuffer()"
                *ngIf="!appState.isHomeownerView() && dashboardStore.dashboardMode == null"
                [disabled]="dashboardStore?.inProcess"
                class="d-md-none btn btn-secondary btn-circle m-r-10"
                ngbTooltip="Dashboard Link">
            <i class="material-icons md-24">
                share
            </i>
        </button>

        <button (click)="copyProject()"
                *ngIf="!appState.isHomeownerView() &&  dashboardStore.dashboardMode == null && !dashboardStore.readOnly"
                [disabled]="dashboardStore?.inProcess"
                ngbTooltip="Copy Project"
                class="d-none d-md-inline btn btn-secondary btn-border-radius m-r-10">
            Copy Project
        </button>

        <button (click)="copyProject()"
                *ngIf="!appState.isHomeownerView() && dashboardStore.dashboardMode == null && !dashboardStore.readOnly"
                [disabled]="dashboardStore?.inProcess"
                ngbTooltip="Copy Project"
                class="d-md-none btn btn-secondary btn-circle m-r-10">
            <i class="material-icons md-24">
                copy_all
            </i>
        </button>

        <div *ngIf="dashboardStore.dashboardMode === 'comparison-view'"
             class="toggle-display-container m-r-10">
            <button [disabled]="dashboardStore.inProcess"
                    [popper]="bpDisplayQuotersSelector"
                    [popperShowOnStart]="false"
                    popperPlacement="bottom-start"
                    class="btn btn-secondary btn-border-radius toggle-display"
                    ngbTooltip="{{ 'Select quoters for comparison'}}">
                Columns <i class="material-icons">arrow_drop_down</i>
            </button>

            <popper-content #bpDisplayQuotersSelector>
                <bp-dashboard-display-quoters-selector>
                </bp-dashboard-display-quoters-selector>
            </popper-content>
        </div>

        <button (click)="requestCallback()"
                *ngIf="appState.isHomeownerView()"
                [disabled]="dashboardStore?.inProcess"
                ngbTooltip="Request Callback"
                class="d-none d-md-inline btn btn-secondary btn-border-radius m-r-10">
            Request Callback
        </button>

        <button (click)="requestCallback()"
                [disabled]="dashboardStore?.inProcess"
                ngbTooltip="Request Callback"
                class="d-md-none btn btn-secondary btn-circle m-r-10">
            <i class="material-icons md-24">
                phone_callback
            </i>
        </button>

        <button [disabled]="dashboardStore?.inProcess"
                [popper]="bpExportSelector"
                popperPlacement="bottom-start"
                class="btn btn-secondary btn-border-radius export m-r-10"
                ngbTooltip="Export"
                [openDelay]="MENU_TOOLTIP_OPEN_DELAY">
            <div class="flex align-items-center gap-1">
                <i class="material-icons md-24">
                    file_download
                </i>

                <div class="d-none d-md-block flex align-items-center">
                    Export

                    <i class="material-icons md-24 cursor-pointer">
                        expand_more
                    </i>
                </div>
            </div>
        </button>

        <popper-content #bpExportSelector>
            <bp-export-selector
                [visibleItems]="getVisibleExportItems()"
                (onChanged)="onExportTypeSelected($event)">
            </bp-export-selector>
        </popper-content>
    </div>
</div>

<div class="d-md-none flex m-t-20 justify-content-center text-center">
    <bp-main-view-filter
        *ngIf="dashboardStore.inited && appState.data.scheduleAreaItems?.length && appState.data.stageItems?.length"
        [disabled]="dashboardStore?.inProcess"
        [disabledRoomMode]="true"
        (onChanged)="onMainViewFilterChangedFunc($event)"
        [initialFilterState]="dashboardStore.filterState"
        [areaItems]="appState.data.scheduleAreaItems"
        [stageItems]="appState.data.stageItems">
    </bp-main-view-filter>
</div>
