import { Component, OnInit, ViewChild } from '@angular/core';
import { DashboardStore } from 'app/flows/scheduler/dashboard/stores/dashboard.store';
import { DashboardService } from 'app/flows/scheduler/dashboard/services/dashboard.service';
import {
    getActualAreaIds,
    IMainViewFilterState
} from 'app/shared/components/common/main-view-filter/main-view-filter.component';
import { EXPORT_TYPES, ExportType } from 'app/shared/constants/export-types';
import { IProject } from 'app/shared/model/project.model';
import { MainFilterInitialStateStorageService } from 'app/shared/services/main-filter-initial-state-storage.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BpAlertService } from 'app/shared/services/bp-alert.service';
import { ExportReportsService, IMainReportData } from 'app/shared/services/export/export-reports.service';
import { FreemiumModalService } from 'app/shared/components/common/freemium-modal/freemium-modal.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ProjectActionsService } from 'app/shared/services/project-actions.service';
import { finalize } from 'rxjs/operators';
import { UserReportModalService } from 'app/account/user-report-modal/user-report-modal.service';
import { AccountService } from 'app/core/auth/account.service';
import { NgxPopperjsContentComponent } from "ngx-popperjs";
import { CopyToClipboardService } from "app/shared/services/copy-to-clipboard";
import { SuccessModalService } from "app/shared/components/common/success-modal/success-modal.service";
import { ProjectApi } from "app/shared/dataservices/project.api";
import { ApplicationStateService } from "app/core/application-state.service";
import {
    RequestCallbackModalService
} from "app/flows/scheduler/schedule/components/request-callback-modal/request-callback-modal.service";

@Component({
    selector: 'bp-dashboard-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['menu.scss']
})
export class DashboardMenuComponent implements OnInit {
    @BlockUI() blockUI: NgBlockUI;

    @ViewChild('bpExportSelector') bpExportSelector: NgxPopperjsContentComponent;

    MENU_TOOLTIP_OPEN_DELAY = 100;

    constructor(
        protected appState: ApplicationStateService,
        protected dashboardService: DashboardService,
        protected dashboardStore: DashboardStore,
        private projectActionsService: ProjectActionsService,
        private requestCallbackModalService: RequestCallbackModalService,
        private mainFilterInitialStateStorageService: MainFilterInitialStateStorageService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private projectApi: ProjectApi,
        private alertService: BpAlertService,
        private exportReportsService: ExportReportsService,
        private freemiumModalService: FreemiumModalService,
        private _accountService: AccountService,
        private _userReportModalService: UserReportModalService,
        private _copyToClipboardService: CopyToClipboardService,
        private _successModalService: SuccessModalService) {
    }

    ngOnInit(): void {
    }

    protected getVisibleExportItems(): ExportType[] {
        const result: ExportType[] = ['pdf', 'docx'];
        if (!this._accountService.isHomeowner()) {
            result.push('csv');
        }
        return result;
    }

    protected onMainViewFilterChangedFunc(filterState: IMainViewFilterState): void {
        this.dashboardStore.filterState = filterState;
        this.mainFilterInitialStateStorageService.store(this.appState.project.id, 'project_overview_scheduler', filterState);
    }

    protected onExportTypeSelected(exportType: ExportType): void {
        this.bpExportSelector.hide();

        this.freemiumModalService.verify('export').then((res) => {
            if (res) {
                this.exportAs(exportType).then((res2) => {
                    if (res2) {
                        this.alertService.success(`Your ${EXPORT_TYPES.find(et => et.id === exportType).label} document will be downloaded shortly. Please wait.`, 10000);
                    }
                });
            }
        });
    }

    protected onShowUpdateButtonClick(): void {
        this.blockUI.start('Please wait...');
        window.location.reload();
    }

    protected onShowAverageRatesButtonClick(): void {
        this.blockUI.start('Please wait...');
        this.dashboardService.addDefaultQuoter().pipe(finalize(() => {
            this.blockUI.stop();
        })).subscribe(() => {
            window.location.reload();
        })
    }

    protected copyDashboardLinkToBuffer(): void {
        this.projectApi.getTempLink(this.appState.project.id).subscribe((tempLink) => {
            this._copyToClipboardService.copyToClipboard(tempLink);

            this._successModalService.open({
                header: `Link successfully copied to clipboard`,
                textHtml: `<div class='strong m-b-10'>
                            <a href='${tempLink}' target='_blank' class="link underlined">${tempLink}</a>
                          </div>`,

            });
        })
    }

    protected copyProject(): void {
        this.projectActionsService.copyProject(this.appState.project).then((newProject: IProject | null) => {
            if (newProject) {
                this.blockUI.start('Your project has been copied. Redirecting to copied project');
                this.router.navigate(['../../dashboard', newProject.id], { relativeTo: this.activatedRoute }).finally(() => {
                    window.location.reload();
                })
            }
        })
    }

    protected requestCallback(): void {
        this.requestCallbackModalService.open();
    }

    private exportAs(exportType: ExportType): Promise<boolean> {
        return new Promise(resolve => {
            const actualAreaIds = getActualAreaIds(this.dashboardStore.filterState, this.appState.data.scheduleAreaItems);

            const mainReportData: IMainReportData = {
                project: this.appState.project,
                filteredScheduleAreas: this.appState.scheduleAreas.filter(scheduleArea => actualAreaIds.indexOf(scheduleArea.id) !== -1),
                filterState: this.dashboardStore.filterState,
                scheduleAreaItems: this.appState.data.scheduleAreaItems,
                stageItems: this.appState.data.stageItems,
                cssElementItems: this.appState.data.cssElementItems,
                buildUpItems: []
            }

            switch (exportType) {
                case 'csv':
                    this._userReportModalService.open('EXCEL').result.then((res) => {
                        if (res) {
                            if (this.dashboardStore.dashboardMode === 'comparison-view') {
                                this.exportReportsService.exportAsExcelComparison(mainReportData, this.dashboardStore.selectedQuoters);
                            } else {
                                this.exportReportsService.exportAsExcelIndividualQuoter(mainReportData, this.appState.project.defaultQuoter);
                            }

                            resolve(res);
                        }
                    })
                    break;
                case 'docx':
                    this._userReportModalService.open('WORD').result.then((res) => {
                        if (res) {
                            if (this.dashboardStore.dashboardMode === 'comparison-view') {
                                this.exportReportsService.exportAsDocxComparison(mainReportData, this.dashboardStore.selectedQuoters);
                            } else {
                                this.exportReportsService.exportAsDocxIndividualQuoter(mainReportData, this.appState.project.defaultQuoter);
                            }

                            resolve(res);
                        }
                    })
                    break;
                case 'pdf':
                    this._userReportModalService.open('PDF').result.then((res) => {
                        if (res) {
                            if (this.dashboardStore.dashboardMode === 'comparison-view') {
                                this.exportReportsService.exportAsPDFComparison(mainReportData, this.dashboardStore.selectedQuoters);
                            } else {
                                this.exportReportsService.exportAsPDFIndividualQuoter(mainReportData, this.appState.project.defaultQuoter);
                            }

                            resolve(res);
                        }
                    })
                    break;
            }
        })
    }
}
