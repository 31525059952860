import { CUSTOM_ELEMENTS_SCHEMA, importProvidersFrom, NgModule } from '@angular/core';
import { NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbDateMomentAdapter } from './util/datepicker-adapter';
import { LabeledValueComponent } from './components/common/labeled-value/labeled-value.component';
import { BpClickOutsideDirective } from './directives/bp-click-outside/bp-click-outside.directive';
import { BpPercentDirective } from './directives/bp-percent/bp-percent.directive';
import { SearchBySelectComponent } from './components/common/search-by-select/search-by-select.component';
import { SelectInputComponent } from './components/common/select-input/select-input.component';
import { ProjectStatusComponent } from './components/projects/project-status/project-status.component';
import { EmptyProjectsComponent } from './components/projects/empty-projects/empty-projects.component';
import { ProjectCardComponent } from './components/projects/project-card/project-card.component';
import { ProjectListComponent } from './components/projects/project-list/project-list.component';
import { RouterModule } from '@angular/router';
import { AddElementModalComponent } from './components/schedule/add-element-modal/add-element-modal.component';
import {
    AddScheduleStageModalComponent
} from './components/schedule/add-schedule-stage-modal/add-schedule-stage-modal.component';
import { AllStagesComponent } from './components/schedule/all-stages/all-stages.component';
import { SelectScheduleAreaComponent } from './components/schedule/select-schedule-area/select-schedule-area.component';
import { AddTaskModalComponent } from './components/schedule/add-task-modal/add-task-modal.component';
import { CurrencyPipe, DecimalPipe } from '@angular/common';
import {
    AddMaterialModalComponent
} from 'app/shared/components/schedule/add-material-modal/add-material-modal.component';
import { ArchiveFilterComponent } from 'app/shared/components/common/archive-filter/archive-filter.component';
import { DatePickerComponent } from 'app/shared/components/common/date-picker/date-picker.component';
import { TaskTotalComponent } from 'app/shared/components/common/task-totals/task-total.component';
import { InlineEditComponent } from 'app/shared/components/common/inline-edit/inline-edit.component';
import {
    InlineSelectEditComponent
} from 'app/shared/components/common/inline-select-edit/inline-select-edit.component';
import {
    EditScheduleTaskNoteModalComponent
} from 'app/shared/components/schedule/edit-schedule-task-note-modal/edit-schedule-task-note-modal.component';
import { BpLogoutComponent } from 'app/shared/logout/logout.component';
import {
    SelectScheduleAreaExtQuoteComponent
} from 'app/shared/components/quote/select-schedule-area-ext-quote/select-schedule-area-ext-quote.component';
import { SpinnerComponent } from 'app/shared/components/common/spinner/spinner.component';
import { ImageUploaderComponent } from 'app/shared/components/common/image-uploader/image-uploader.component';
import { ImagePreviewerComponent } from 'app/shared/components/common/image-previewer/image-previewer.component';
import {
    SelectPaymentProviderComponent
} from 'app/shared/components/common/select-payment-provider/select-payment-provider.component';
import {
    AddMaterialCategoryModalComponent
} from 'app/shared/components/common/add-material-category-modal/add-material-category-modal.component';
import { StripePaymentResultComponent } from 'app/shared/stripe-payment-result/stripe-payment-result.component';
import {
    SelectPaymentProviderSchedulerModalComponent
} from 'app/shared/components/common/select-payment-provider-scheduler-modal/select-payment-provider-scheduler-modal.component';
import {
    SelectPaymentProviderQuoterModalComponent
} from 'app/shared/components/common/select-payment-provider-quoter-modal/select-payment-provider-quoter-modal.component';
import { AbsoluteUrlPipe } from 'app/shared/pipes/absolute-url.pipe';
import {
    ProjectDetailsCostVisualizationComponent
} from 'app/shared/components/projects/project-details-cost-visualization/project-details-cost-visualization.component';
import { PaymentProviderLabelPipe } from 'app/shared/pipes/payment-provider-label.pipe';
import { NgxMaskConfig, NgxMaskDirective, NgxMaskPipe, provideEnvironmentNgxMask } from 'ngx-mask';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { AddProjectCardComponent } from 'app/shared/components/projects/add-project-card/add-project-card.component';
import {
    ProjectAttachmentsComponent
} from 'app/shared/components/common/project-attachments/project-attachments.component';
import { ProjectImageComponent } from 'app/shared/components/common/project-image/project-image.component';
import {
    ProjectAttachmentsFeOnlyComponent
} from 'app/shared/components/common/project-attachments-fe-only/project-attachments-fe-only.component';
import {
    InlineCheckboxEditComponent
} from 'app/shared/components/common/inline-checkbox-edit/inline-checkbox-edit.component';
import { LoaderComponent } from 'app/shared/components/common/loader/loader.component';
import { LoadingDirective } from 'app/shared/components/common/loader/loading.directive';
import { ProjectCardXSComponent } from 'app/shared/components/projects/project-card-xs/project-card-xs.component';
import {
    ShowSubstagesModeSelectorComponent
} from 'app/shared/components/common/show-substages-mode-selector/show-substages-mode-selector.component';
import { ExportSelectorComponent } from 'app/shared/components/common/export-selector/export-selector.component';
import {
    CostPlanTableComponent
} from 'app/shared/components/projects/project-details-cost-visualization/components/cost-plan-table/cost-plan-table.component';
import {
    DisplayQuotersSelectorComponent
} from 'app/shared/components/projects/project-details-cost-visualization/components/display-quoters-selector/display-quoters-selector.component';
import { BpFrontendSharedLibsModule } from 'app/shared/shared-libs.module';
import { BpFrontendSharedCommonModule } from 'app/shared/shared-common.module';
import { BpLoginComponent } from 'app/shared/login/login.component';
import { HasAnyAuthorityDirective } from 'app/shared/auth/has-any-authority.directive';
import {
    SpecificMarginActionSelectorComponent
} from 'app/shared/components/common/specific-margin-action-selector/specific-margin-action-selector.component';
import { MultiTogglerComponent } from 'app/shared/components/common/multi-toggler/multi-toggler.component';
import { SafePipe } from 'app/shared/pipes/safe.pipe';
import {
    BpInvalidControlScrollDirective
} from 'app/shared/directives/bp-invalid-control-scroll/bp-invalid-control-scroll.directive';
import { ConfirmModalComponent } from 'app/shared/components/common/confirm-modal/confirm-modal.component';
import { SuccessModalComponent } from 'app/shared/components/common/success-modal/success-modal.component';
import { FreemiumModalComponent } from 'app/shared/components/common/freemium-modal/freemium-modal.component';
import {
    ThanksForSubscribingModalComponent
} from 'app/shared/components/common/thanks-for-subscribing-modal/thanks-for-subscribing-modal.component';
import {
    AfterSignupWizardModalComponent
} from 'app/shared/components/common/after-signup-wizard-modal/after-signup-wizard-modal.component';
import { ArraySortPipe } from 'app/shared/pipes/array-sort.pipe';
import {
    ProjectCommentsModalComponent
} from 'app/shared/components/common/comments-modal/project-comments-modal.component';
import { MainViewFilterComponent } from 'app/shared/components/common/main-view-filter/main-view-filter.component';
import {
    HowToCreateProjectModalComponent
} from 'app/shared/components/projects/how-to-create-project-modal/how-to-create-project-modal.component';
import {
    ServiceSelectorModalComponent
} from 'app/shared/components/projects/service-selector-modal/service-selector-modal.component';
import {
    EstimatorsWorkingOnProjectModalComponent
} from 'app/shared/components/projects/estimators-working-on-project-modal/estimators-working-on-project-modal.component';
import {
    ThanksForSubmittingYourProjectModalComponent
} from 'app/shared/components/projects/thanks-for-submitting-your-project-modal/thanks-for-submitting-your-project-modal.component';
import {
    BpTextareaAutoresizeDirective
} from 'app/shared/directives/bp-textarea-autoresize/bp-textarea-autoresize.directive';
import {
    AddCssElementModalComponent
} from 'app/shared/components/common/add-css-element-modal/add-css-element-modal.component';
import { BpSortDirective } from 'app/shared/directives/bp-sort-directive.directive';
import { BpSortByDirective } from 'app/shared/directives/bp-sort-by-directive.directive';
import { BlockUIModule } from 'ng-block-ui';
import { AddBuildUpModalComponent } from 'app/shared/components/common/add-build-up-modal/add-build-up-modal.component';
import { NgxPopperjsModule, NgxPopperjsPlacements, NgxPopperjsTriggers } from "ngx-popperjs";
import { CostPlanChartComponent } from "app/shared/components/charts/pie-chart/cost-plan-chart.component";
import { ItemsSelectorComponent } from "app/shared/components/common/items-selector/items-selector.component";

const maskConfig: Partial<NgxMaskConfig> = {
    validation: false,
};

@NgModule({
    imports: [
        BpFrontendSharedLibsModule,
        BpFrontendSharedCommonModule,
        NgSelectModule,
        LeafletModule,
        RouterModule,
        BlockUIModule,
        NgxPopperjsModule.forRoot({
            placement: NgxPopperjsPlacements.BOTTOM,
            applyClass: 'bp-popper',
            preventOverflow: true,
            hideOnScroll: true,
            hideOnClickOutside: true,
            trigger: NgxPopperjsTriggers.click,
            appendTo: 'body'
        }),
        NgxMaskDirective,
        NgxMaskPipe,
        CostPlanChartComponent
    ],
    declarations: [
        BpLoginComponent,
        BpLogoutComponent,
        HasAnyAuthorityDirective,
        LabeledValueComponent,
        BpClickOutsideDirective,
        BpInvalidControlScrollDirective,
        BpPercentDirective,
        SearchBySelectComponent,
        SelectInputComponent,
        ProjectStatusComponent,
        EmptyProjectsComponent,
        ProjectCardComponent,
        ProjectCardXSComponent,
        AddProjectCardComponent,
        ProjectListComponent,
        AddScheduleStageModalComponent,
        AddTaskModalComponent,
        AddElementModalComponent,
        AddBuildUpModalComponent,
        AddMaterialModalComponent,
        AddCssElementModalComponent,
        AddMaterialCategoryModalComponent,
        AllStagesComponent,
        SelectScheduleAreaComponent,
        SelectScheduleAreaExtQuoteComponent,
        MainViewFilterComponent,
        ImageUploaderComponent,
        ImagePreviewerComponent,
        ArchiveFilterComponent,
        DatePickerComponent,
        TaskTotalComponent,
        InlineEditComponent,
        InlineSelectEditComponent,
        InlineCheckboxEditComponent,
        EditScheduleTaskNoteModalComponent,
        SpinnerComponent,
        SelectPaymentProviderQuoterModalComponent,
        SelectPaymentProviderSchedulerModalComponent,
        SelectPaymentProviderComponent,
        StripePaymentResultComponent,
        ProjectDetailsCostVisualizationComponent,
        ArraySortPipe,
        AbsoluteUrlPipe,
        SafePipe,
        PaymentProviderLabelPipe,
        ProjectAttachmentsComponent,
        ProjectAttachmentsFeOnlyComponent,
        ProjectImageComponent,
        LoaderComponent,
        LoadingDirective,
        ItemsSelectorComponent,
        ShowSubstagesModeSelectorComponent,
        ExportSelectorComponent,
        CostPlanTableComponent,
        DisplayQuotersSelectorComponent,
        SpecificMarginActionSelectorComponent,
        MultiTogglerComponent,
        ConfirmModalComponent,
        SuccessModalComponent,
        FreemiumModalComponent,
        ThanksForSubscribingModalComponent,
        AfterSignupWizardModalComponent,
        ProjectCommentsModalComponent,
        HowToCreateProjectModalComponent,
        ServiceSelectorModalComponent,
        EstimatorsWorkingOnProjectModalComponent,
        ThanksForSubmittingYourProjectModalComponent,
        BpTextareaAutoresizeDirective,
        BpSortDirective,
        BpSortByDirective
    ],
    providers: [
        {
            provide: NgbDateAdapter,
            useClass: NgbDateMomentAdapter
        },
        CurrencyPipe,
        DecimalPipe,
        ArraySortPipe,
        AbsoluteUrlPipe,
        PaymentProviderLabelPipe,
        SafePipe,
        importProvidersFrom(BlockUIModule.forRoot()),
        provideEnvironmentNgxMask(maskConfig)
    ],
    exports: [
        BpFrontendSharedCommonModule,
        BlockUIModule,
        NgSelectModule,
        NgxPopperjsModule,
        CostPlanChartComponent,
        BpLoginComponent,
        BpLogoutComponent,
        HasAnyAuthorityDirective,
        LabeledValueComponent,
        BpClickOutsideDirective,
        BpInvalidControlScrollDirective,
        BpPercentDirective,
        SearchBySelectComponent,
        SelectInputComponent,
        ProjectStatusComponent,
        EmptyProjectsComponent,
        ProjectCardComponent,
        ProjectCardXSComponent,
        AddProjectCardComponent,
        ProjectListComponent,
        AddScheduleStageModalComponent,
        AddTaskModalComponent,
        AddElementModalComponent,
        AddBuildUpModalComponent,
        AddMaterialModalComponent,
        AddCssElementModalComponent,
        AddMaterialCategoryModalComponent,
        AllStagesComponent,
        SelectScheduleAreaComponent,
        SelectScheduleAreaExtQuoteComponent,
        MainViewFilterComponent,
        ImageUploaderComponent,
        ImagePreviewerComponent,
        ArchiveFilterComponent,
        DatePickerComponent,
        TaskTotalComponent,
        InlineEditComponent,
        InlineSelectEditComponent,
        InlineCheckboxEditComponent,
        EditScheduleTaskNoteModalComponent,
        SpinnerComponent,
        SelectPaymentProviderComponent,
        ProjectDetailsCostVisualizationComponent,
        ArraySortPipe,
        AbsoluteUrlPipe,
        PaymentProviderLabelPipe,
        ProjectAttachmentsComponent,
        ProjectAttachmentsFeOnlyComponent,
        ProjectImageComponent,
        LoaderComponent,
        LoadingDirective,
        ItemsSelectorComponent,
        ShowSubstagesModeSelectorComponent,
        ExportSelectorComponent,
        DisplayQuotersSelectorComponent,
        CostPlanTableComponent,
        SpecificMarginActionSelectorComponent,
        MultiTogglerComponent,
        SafePipe,
        ConfirmModalComponent,
        SuccessModalComponent,
        FreemiumModalComponent,
        ThanksForSubscribingModalComponent,
        AfterSignupWizardModalComponent,
        ProjectCommentsModalComponent,
        HowToCreateProjectModalComponent,
        ServiceSelectorModalComponent,
        EstimatorsWorkingOnProjectModalComponent,
        ThanksForSubmittingYourProjectModalComponent,
        BpTextareaAutoresizeDirective,
        BpSortDirective,
        BpSortByDirective,
        NgxMaskDirective,
        NgxMaskPipe
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class BpFrontendSharedModule {
    static forRoot() {
        return {
            ngModule: BpFrontendSharedModule
        };
    }
}
